/* eslint-disable react/display-name */
import Layout, { LayoutProps } from "./_organisms/Layout/Layout";

type WithLayoutProps = Omit<LayoutProps, "children">;

const withLayout =
  <P extends object>(
    Component: React.FC<P>,
    layoutProps: WithLayoutProps,
  ): React.FC<P> =>
  ({ ...componentProps }) => {
    return (
      <Layout {...layoutProps}>
        <Component {...componentProps} />
      </Layout>
    );
  };

export default withLayout;
