/* eslint-disable react/display-name */
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "reactstrap";
import routes, { Route, ProtectedRouteCondition } from "../routes";
import { isUserSignedIn } from "../services/auth";
import { getRouteByPathname } from "../utils";

const withRouteProtection =
  <P extends object>(Component: React.FC<P>): React.FC<P> =>
  ({ ...props }) => {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(true);

    const activeRoute = useMemo(
      () => getRouteByPathname(router.pathname),
      [router.pathname],
    );

    /*
    Reirect accoring to route conditions set routes.ts
    */

    const condition = useMemo(
      () => (activeRoute ? activeRoute.condition : null),
      [activeRoute],
    );

    useEffect(() => {
      if (activeRoute) {
        redirectUserBasedOnAuthentication(activeRoute);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition]);

    const redirectUserBasedOnAuthentication = useCallback(
      async (activeRoute: Route) => {
        const isAuthenticated = await isUserSignedIn();

        switch (activeRoute.condition) {
          case ProtectedRouteCondition.SIGNED_IN:
            if (!isAuthenticated) {
              router.push(routes.signin.path);
            } else {
              setIsLoading(false);
            }
            break;
          case ProtectedRouteCondition.SIGNED_OUT:
            if (isAuthenticated) {
              router.push(routes.home.path);
            } else {
              setIsLoading(false);
            }
            break;
          case ProtectedRouteCondition.FORBIDDEN:
            // all forbidden pages should have a redirect defined
            break;
          default:
            setIsLoading(false);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    /*
      Reirect accoring to redirect definition in routes.ts
    */
    useEffect(() => {
      if (activeRoute?.redirect) {
        router.push(activeRoute.redirect);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeRoute]);

    return isLoading ? (
      <div className="w-100 min-vh-100 d-flex align-items-center justify-content-center">
        <Spinner color="primary" />
      </div>
    ) : (
      <Component {...props} />
    );
  };

export default withRouteProtection;
