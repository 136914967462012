import { useTranslation } from "next-i18next";
import { Trash2, X } from "react-feather";
import { Modal, ModalBody, Button } from "reactstrap";
import moment from "moment";

import routes, { apiRoutes } from "../../../routes";
import { useGlobalState } from "../../../context/State";
import { CalendlyEvent } from "../../api/calendly/getEvent.page";

import Card from "../../../components/_organisms/Card/Card";
import { TextButton } from "../../../components/_atoms";
import styles from "./ProjectOverview.module.scss";
import { useCallback, useState } from "react";
import { ProjectWithCalendlyData, ProjectStatus } from "./ProjectOverview";
import { AlertSetter } from "../../../components/withAlerts";
import useApi from "../../../services/useApi";

type ProjectOverviewCardProps = ProjectWithCalendlyData & AlertSetter;

const ProjectOverviewCard: React.FC<ProjectOverviewCardProps> = ({
  id,
  name,
  status,
  stepIndexInProgress,
  appointmentDetails,
  setAlert,
}) => {
  const { t } = useTranslation("projects");
  const [{ projects }, { setProjects }] = useGlobalState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fetchApi = useApi({ setAlert });

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen],
  );

  const getProjectStatusDescription = useCallback(
    ({
      status,
      appointmentDetails,
    }: {
      status: string;
      appointmentDetails?: CalendlyEvent;
    }) => {
      const callDate = appointmentDetails ? (
        <>
          {moment(new Date(appointmentDetails.resource.start_time)).format(
            "HH:mma",
          )}{" "}
          -{" "}
          {moment(new Date(appointmentDetails.resource.end_time)).format(
            "HH:mma",
          )}
        </>
      ) : (
        "tbd"
      );

      switch (status) {
        case ProjectStatus.INCOMPLETE:
          return <span className="text-muted">{t("status.incomplete")}</span>;
        case ProjectStatus.LEAD:
          return (
            <span>
              {t("status.lead")} {callDate}
            </span>
          );

        default:
          return "tbd";
      }
    },
    [t],
  );

  return (
    <div className={styles.item}>
      <Card
        className={`m2 h-100 ${styles.card}`}
        title={
          <div className={styles.cardTitle}>
            <a
              href={`${routes.projectCreate.path}?${new URLSearchParams({
                id: id,
                step: `${
                  status === ProjectStatus.INCOMPLETE ? stepIndexInProgress : 0
                }`,
              })}`}
            >
              {name}
            </a>
            <div className={styles.deleteButtonWrapper}>
              <TextButton className={styles.deleteButton} onClick={toggleModal}>
                <Trash2 />
              </TextButton>
            </div>
          </div>
        }
      >
        <div className="mt-2">
          {status && (
            <div className="mt-3">
              {getProjectStatusDescription({
                status,
                appointmentDetails,
              })}
            </div>
          )}
        </div>
      </Card>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        className="d-flex align-items-center h-100"
      >
        <ModalBody>
          <button
            className="btn-close w-auto h-auto"
            data-bs-dismiss="modal"
            type="button"
            aria-label="Close"
            color="secondary"
          >
            <X />
          </button>
          <h1 className="mb-4">{t("delete.prompt")}</h1>
          <p>
            {t("delete.text")} {name}
          </p>
          <div className="d-flex justify-content-end gap-4">
            <Button
              color="danger"
              onClick={async () => {
                const params = new URLSearchParams({
                  projectId: id,
                });
                const canceled = await fetchApi<string>(
                  `${apiRoutes.db.projects.path}?${params}`,
                  {
                    method: "DELETE",
                  },
                );

                if (canceled) {
                  setProjects(projects.filter((project) => project.id !== id));
                }
                toggleModal();
              }}
            >
              {t("delete.confirm")}
            </Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              {t("delete.cancel")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProjectOverviewCard;
