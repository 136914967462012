import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import withRouteProtection from "../../components/withRouteProtection";
import ProjectOverview from "./overview/ProjectOverview";

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? "de", [
        "auth",
        "common",
        "projects",
      ])),
    },
  };
};

export default withRouteProtection(ProjectOverview);
