import { useEffect } from "react";

import { Plus } from "react-feather";
import { Col, Row, Container } from "reactstrap";

import { useTranslation } from "next-i18next";

import { useGlobalState } from "../../../context/State";
import routes, { apiRoutes } from "../../../routes";
import { CalendlyEvent } from "../../api/calendly/getEvent.page";
import { ProjectCreation } from "../create/CreateProject";
import styles from "./ProjectOverview.module.scss";
import { Card } from "../../../components/_organisms";
import ProjectOverviewCard from "./ProjectOverviewCard";
import withAlerts, { AlertSetter } from "../../../components/withAlerts";
import withLayout from "../../../components/withLayout";
import useApi from "../../../services/useApi";
import { ProjectsApiErrors } from "../../api";
import { Project } from "../../../services/db/entities/Project";
import Link from "next/link";

export enum ProjectStatus {
  LEAD = "lead",
  INCOMPLETE = "incomplete",
}

export type ProjectWithCalendlyData = ProjectCreation & {
  appointmentDetails?: CalendlyEvent;
};

const ProjectOverview: React.FC<AlertSetter> = ({ setAlert }) => {
  const { t } = useTranslation("projects");
  const [{ projects }, { setProjects }] = useGlobalState();
  const fetchApi = useApi({ setAlert });

  useEffect(() => {
    (async () => {
      const projects = await fetchApi<Project[], ProjectsApiErrors>(
        apiRoutes.db.projects.path,
        {
          method: "GET",
        },
      );

      if (projects) {
        const projectsWithCalendlyData = await Promise.all(
          projects.map(async (project) => {
            if (project.appointment) {
              const params = new URLSearchParams({
                url: project.appointment,
              });

              const appointmentDetails = await fetchApi<CalendlyEvent>(
                `${apiRoutes.calendly.getEvent.path}?${params}`,
                {
                  method: "GET",
                },
              );

              return {
                ...project,
                stepIndexInProgress: project.stepIndexInProgress ?? undefined,
                appointmentDetails: appointmentDetails,
              };
            } else {
              return {
                ...project,
                stepIndexInProgress: project.stepIndexInProgress ?? undefined,
              };
            }
          }),
        );
        projectsWithCalendlyData
          ? setProjects(projectsWithCalendlyData)
          : setProjects([]);
      }
    })();
  }, [setProjects, fetchApi]);

  return (
    <>
      <h1 className="mt-8 mb-6">{t("title")}</h1>
      <Container>
        <div className={styles.wrapper}>
          <Row>
            {projects.map((project) => (
              <Col
                className="mb-5 pb-5 mt-md-0 d-flex align-items-stretch"
                xs="auto"
                sm={4}
                key={project.id}
              >
                <ProjectOverviewCard {...project} setAlert={setAlert} />
              </Col>
            ))}
            <Col className="mb-5 pb-5 mt-md-0 h-100" xs="auto" sm={4}>
              <div className={styles.item}>
                <Link href={routes.projectCreate.path}>
                  <a>
                    <Card
                      className={`m2 h-100 ${styles.addProject}`}
                      title={
                        <div className={styles.sansSerif}>
                          {t("create.add")}
                        </div>
                      }
                    >
                      <Plus />
                    </Card>
                  </a>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default withLayout(withAlerts(ProjectOverview), {
  title: "title",
  ns: "projects",
});
