import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { AlertSetter, AlertTypes } from "../components/withAlerts";
import {
  ApiResponse,
  CalendlyApiErrors,
  ProjectsApiErrors,
} from "../pages/api";
import { getUserApiAccessToken } from "./auth";

export enum CommonApiErrors {
  NOT_AUTHENTICATED = "NotAuthenticated",
  TIMEOUT = "timeout",
}

const useApi = ({ setAlert }: Partial<AlertSetter>) => {
  const { t } = useTranslation("common");

  return useCallback(
    async <
      RequestSuccess = unknown,
      RequestError extends
        | ProjectsApiErrors
        | CalendlyApiErrors
        | CommonApiErrors = CommonApiErrors,
    >(
      input: RequestInfo | URL,
      init?: RequestInit | undefined,
    ) => {
      try {
        const accessToken = await getUserApiAccessToken();
        if (!accessToken) {
          throw new Error(CommonApiErrors.NOT_AUTHENTICATED);
        }

        const response = await fetch(input, {
          ...init,
          headers: {
            ...init?.headers,
            Authorization: accessToken,
          },
        });

        const body: ApiResponse<RequestSuccess, RequestError> =
          await response.json();

        if ("success" in body) {
          return body.data;
        } else {
          throw new Error(body.error);
        }
      } catch (_e) {
        const error = _e as Error;
        console.error(error);
        if (setAlert) {
          setAlert({
            type: AlertTypes.ERROR,
            key: error.message,
            message: t(`errors.${error.message}`),
          });
        }
      }
    },
    [setAlert, t],
  );
};

export default useApi;
